import React, { useState } from 'react';
import Layout from '../../../components/Layout';
import NavOne from '../../../components/NavOne';
import { Spoiler } from '@mantine/core';
import PageHeader from '../../../components/PageHeader';
import ModalVideo from 'react-modal-video';
import Footer from '../../../components/Footer';

import g1 from '../../../assets/images/2023/nlng-upgrades-bed/1.jpeg';
import g2 from '../../../assets/images/2023/nlng-upgrades-bed/2.jpeg';
import g3 from '../../../assets/images/2023/nlng-upgrades-bed/3.jpeg';
import g4 from '../../../assets/images/2023/nlng-upgrades-bed/8.jpeg';
import g5 from '../../../assets/images/2023/nlng-upgrades-bed/5.jpeg';
import g6 from '../../../assets/images/2023/nlng-upgrades-bed/6.jpeg';
import g7 from '../../../assets/images/2023/nlng-upgrades-bed/7.jpeg';
import g8 from '../../../assets/images/2023/nlng-upgrades-bed/11.jpeg';
import g9 from '../../../assets/images/2023/nlng-upgrades-bed/9.jpeg';
import g10 from '../../../assets/images/2023/nlng-upgrades-bed/10.jpeg';
import g11 from '../../../assets/images/2023/nlng-upgrades-bed/4.jpeg';
// import testimonial1 from '../../../assets/images/videos/testimonial2.png';


const BlogLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  return (
    <section className='gallery-one'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='course-details__top-left'>
              <h2 className='course-details__title'>
              NLNG upgrades 120-bed Obstetrics and Gynaecology block at LUTH
              </h2>
            </div>
          </div>
          <Spoiler maxHeight={280} hideLabel='Hide' showLabel='Show More'>
            <div className='row'>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g1} alt='' />
                  <a href={g1} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g2} alt='' />
                  <a href={g2} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g3} alt='' />
                  <a href={g3} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g4} alt='' />
                  <a href={g4} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g5} alt='' />
                  <a href={g5} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g6} alt='' />
                  <a href={g6} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g7} alt='' />
                  <a href={g7} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g8} alt='' />
                  <a href={g8} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g9} alt='' />
                  <a href={g9} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g10} alt='' />
                  <a href={g10} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='gallery-one__single'>
                  <img src={g11} alt='' />
                  <a href={g11} className='gallery-one__popup img-popup'>
                    <i className='kipso-icon-magnifying-glass'></i>
                  </a>
                </div>
              </div>
              {/* <div className='col-lg-4'>
                <div
                  className='video-three__box'
                  style={{ backgroundImage: `url(${g3})` }}
                >                  
                  <div className='video-three__btn-box'>
                    <ModalVideo
                      channel='youtube'
                      isOpen={isOpen}
                      videoId='A7sbG0_dJJA'
                      onClose={() => setIsOpen(false)}
                    />
                    <div
                      onClick={() => setIsOpen(true)}
                      onKeyDown={() => setIsOpen(true)}
                      role='button'
                      tabIndex={0}
                      className='video-three__popup'
                    >
                      <i className='fas fa-play'></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div
                  className='video-three__box'
                  style={{ backgroundImage: `url(${g5})` }}
                >                  
                  <div className='video-three__btn-box'>
                    <ModalVideo
                      channel='youtube'
                      isOpen={isOpen2}
                      videoId='c_LmWpA-bUc'
                      onClose={() => setIsOpen2(false)}
                    />
                    <div
                      onClick={() => setIsOpen2(true)}
                      onKeyDown={() => setIsOpen2(true)}
                      role='button'
                      tabIndex={0}
                      className='video-three__popup'
                    >
                      <i className='fas fa-play'></i>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </Spoiler>
        </div>
      </div>
    </section>
  );
};

const EventPage = () => {
  return (
    <Layout pageTitle='Event Gallery | LUTH'>
      <NavOne />
      <PageHeader title='' />
      <BlogLayout />
      <Footer />
    </Layout>
  );
};

export default EventPage;
